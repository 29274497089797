import { getCost, getPayQrCodeByCost } from '@/api/clean';
export default {
  name: 'CleanPayment',
  props: ['id'],
  data() {
    return {
      info: {}
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    getPage() {
      getCost({
        id: this.id
      }).then(res => {
        if (res.data.code === 200) {
          getPayQrCodeByCost({
            costId: res.data.result.id
          }).then(res => {
            if (res.data.code === '10000') {
              this.info = res.data.data;
            }
          });
        }
      });
    },
    onCopy() {
      let input = document.createElement('input');
      input.value = this.info.payQrUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast('复制成功');
    }
  }
};