var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-top"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("扫码支付账单")]), _c('p', {
    staticClass: "price"
  }, [_vm._v("¥"), _c('span', [_vm._v(_vm._s(_vm.info.totalAmount))])]), _c('p', {
    staticClass: "des"
  }, [_vm._v("支付金额")])]), _c('div', {
    staticClass: "card-bottom"
  }, [_c('p', {
    staticClass: "user"
  }, [_vm._v(_vm._s(_vm.info.tenantName) + "-" + _vm._s(_vm.info.tenantPhone))]), _c('p', {
    staticClass: "project"
  }, [_vm._v(_vm._s(_vm.info.shareText))]), _c('div', {
    staticClass: "qrCode"
  }, [_c('img', {
    attrs: {
      "src": _vm.info.payQrCode,
      "alt": ""
    }
  })]), _vm._m(0)])]), _c('div', {
    staticClass: "share"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("其它邀请方式")]), _c('div', {
    staticClass: "link"
  }, [_c('div', {
    staticClass: "item",
    on: {
      "click": _vm.onCopy
    }
  }, [_vm._m(1)])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "des"
  }, [_c('p', [_c('img', {
    attrs: {
      "src": require("../../assets/images/icon-alipay.png"),
      "alt": ""
    }
  }), _vm._v(" 支付宝 ")]), _c('p', [_c('img', {
    attrs: {
      "src": require("../../assets/images/icon-wechat.png"),
      "alt": ""
    }
  }), _vm._v(" 微信支付 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', [_c('img', {
    attrs: {
      "src": require("../../assets/images/icon-link.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("复制链接")])]);
}];
export { render, staticRenderFns };